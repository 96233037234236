.evFooterContainer {
    display: inherit;
    align-items: center;    
    justify-content: center;
    margin: 1rem 0;
    width: 100%;
}

.evFooterContainer a,
.evFooterContainer span {
    color: #6d6d6d;
    font-size: 0.75rem;
    text-transform: uppercase;
}

.evFooterLink {
    text-decoration: none;
}

.evFooterLink:hover,
.evFooterLink:active,
.evFooterLink:focus {
    text-decoration: underline;
}

.evFooterLinkSeparator {
    padding: 0 .5rem;
}
