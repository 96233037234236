.evHeaderContainer {
    display: inherit;
    flex-wrap: wrap;
    width: 100%;    
}

.evSubBanner, 
.evHeroBanner {
    display: inherit;
    align-items: center;
    width: 100%;
}

.evHeroBanner {
    justify-content: center;
    border-bottom: 0.75rem solid orange;
    padding: 3rem 0 1.5rem 0;
}

.evHeroBanner img {
    width: 20%;
}

.evSubBanner {
    justify-content: flex-end;
    border-top: 0.19rem solid gray;
}

.evSubBanner span {
    margin-right: 0.3rem;
}

.evSubBanner img {
    margin-right: 2.5rem;
    height: 0.75rem;
    width: 4.375rem;
}

@media screen and (max-width: 1023px) {
    .evHeroBanner {
        padding: 0.75rem 0;        
        border-bottom: 0.375rem solid orange;
    }

    .evHeaderContainer {
        margin-bottom: 0.5rem;
    }

    .evHeroBanner img {
        width: 35%;
    } 

    .evSubBanner span {
        font-size: .8rem;
    }

    .evSubBanner img {
        height: 0.625rem;
        margin-right: 0.625rem;
        width: 3rem;
    }
} 