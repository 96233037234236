:root {
    --spinner-size: 8rem; 
    --spinner-bg: transparent; 
    --spinner-color: orange;
    --spinner-time:.7s;
    --spinner-border: 0.3125rem;
}

.evRedirectMessage {
    display: flex;
    justify-content: center;
    margin-top: 3.5rem;
    font-size: 1.5rem;
}

.evRedirectSpinner {
    margin-top: 3.5rem;
    height: var(--spinner-size);
    width: var(--spinner-size);
    background-color:var(--spinner-bg);
    border-radius: 50%; 
    border-bottom: var(--spinner-border) solid var(--spinner-color);
    border-top: var(--spinner-border) solid var(--spinner-color);
    border-left: var(--spinner-border) solid transparent;
    border-right: var(--spinner-border) solid transparent;
    animation: spinner-rotate var(--spinner-time) ease 0s infinite;
}

@keyframes spinner-rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: 0;
    }
    50% {
        -webkit-transform: rotate(-359deg);
        transform: rotate(-359deg);
        opacity: 0.5;
    }
    65% {
        -webkit-transform: rotate(-467deg);
        transform: rotate(-467deg);
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: rotate(-719deg);
        transform: rotate(-719deg);
    }
}

@media screen and (max-width: 996px) {
    .evRedirectMessage {
        margin-top: 3rem;
    }
}