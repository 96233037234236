.ev-clerk-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;        
}

.ev-clerk-wrapper h1,
.ev-clerk-wrapper p,
.ev-clerk-wrapper span,
.ev-clerk-wrapper label,
.cl-otpCodeFieldInput,
.cl-formFieldInput {
    color: #444;
}

#signOutOfOtherSessions-field {
    color: #fff;
}

.ev-clerk-wrapper a:focus,
.ev-clerk-wrapper button:focus,
.ev-clerk-wrapper input:focus {
    box-shadow: unset;
}

.ev-clerk-wrapper a,
.cl-headerBackRow p,
.cl-formResendCodeLink  {    
    color: #1e90ff;
}

.ev-clerk-wrapper a:hover,
.ev-clerk-wrapper a:focus,
.ev-clerk-wrapper a:active,
.cl-formResendCodeLink:hover,
.cl-formResendCodeLink:focus,
.cl-formResendCodeLink:active {
    color: #1e90ff;
}

.cl-formButtonPrimary {
    background-color: #fff;
    color: #3caaa2;
    border: 1px solid #3caaa2;
    font-size: 0.8125rem;
    height: 42px;
}

.cl-formButtonPrimary:hover,
.cl-formButtonPrimary:focus,
.cl-formButtonPrimary:active {
    background-color: #3caaa2;
    color: #fff;
}

.cl-card {
    padding: 1rem;
}

.cl-formFieldInput {
    height: 42px;
    accent-color: #3caaa2;
}

.cl-formFieldInput:focus {
    border-color: #3caaa2;
    box-shadow: unset;
}

.cl-identityPreviewEditButton {
    color: #3caaa2;
}

.cl-identityPreviewEditButton:hover,
.cl-identityPreviewEditButton:focus,
.cl-identityPreviewEditButton:active {
    color:#3caaa2;
}

.cl-otpCodeFieldInput:focus {
    border-color: #3caaa2;
}

.cl-logoBox {
    display: none;
}

.cl-identityPreview > p:not(.cl-identityPreviewText) {
    font-size: 1.25em;
    line-height: 1.05;
    align-self: flex-start;
    padding-bottom: 2px;
}
