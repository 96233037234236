html {
    background-color: #fafafa;
    font-family: "Roboto", sans-serif;
}

body {    
    margin: 0;
}

:root {    
    line-height: 1.2;
    color: #444;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.ev-welcome {
    width: 100%;
    text-align: center;
    margin: 1rem 0 0.313rem 0;
    font-size: 1.875rem;
    font-weight: 700;
}